@font-face {
  font-family: 'IGRegular';
  src: local('InnovatorGrotesk-Regular'), url(./fonts/InnovatorGrotesk-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'IGBold';
  src: local('InnovatorGrotesk-Bold'), url(./fonts/InnovatorGrotesk-Bold.woff2) format('woff2');
}

:root {
  --text-color: #C0C0C0;
  --link-color: white;
  --accent-color: #C9FD67;
  --background-color: #272727;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "IGRegular", sans-serif;
  background: #272727;
  color: var(--text-color);
  margin: 0;
  padding: 0;
  line-height: 1.5;

}