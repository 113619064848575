/*
H1 H2
*/
h1, h2 {
  position: relative;
  font-size: 8em;
  margin: 0;
  font-weight: normal;
  color: white;
  text-shadow: 0px 3px 2px rgba(0, 0, 0, 0.5);
  line-height: 1.2;
  text-transform: uppercase;
}

h3 {
  margin: 0;
  color: white;
  font-weight: normal;
  font-size: 1.3em;
}

h4 {
  color: white;
  font-size: 1.2rem;
  font-family: "IGBold", sans-serif;
  text-shadow: none;
  text-transform: uppercase;
  margin-bottom: 0;
}

@media screen and (max-width: 1200px) {
  h1, h2 {
    font-size: 7em;
  }
  h3 {
    font-size: 1.2em;
  }
}

@media screen and (max-width: 1030px) {
  h1, h2 {
    font-size: 6em;
  }
  h3 {
    font-size: 1em;
  }
}

@media screen and (max-width: 770px) {
  h1, h2 {
    font-size: 5em;
  }
}

@media screen and (max-width: 620px) {
  h1, h2 {
    font-size: 4em;
  }
}

p {
  font-size: 0.9em;
}


/*
MARK: COMPONENTS
*/
span.service {
  font-family: "IGRegular", sans-serif;
  font-size: 0.3em;
  font-weight: normal;
  color: var(--accent-color);
  position: absolute;
  top: 0.2em;
  margin-left: 0.3em;
}

/* 
MARK: CONTAINER 
*/
.container {
  margin: 6em auto;
  padding: 2em 5vw;
  max-width: 1200px;
}

.container:nth-of-type(1) {
  margin: 0em auto;
}

.container:nth-of-type(2) {
  margin-top: 0;
}

/* 
MARK: HEADER 
*/
header {
  display: flex;
  gap: 3em;
  align-items: center
}

header .logo {
  width: 90px;
}

/* 
MARK: HEADER media
*/

@media screen and (max-width: 690px) {
  header {
    flex-direction: column;
    align-items: start;
    gap: 1em
  }
}

@media screen and (max-width: 520px) {
  header {
    gap: 2em
  }
}


/*
MARK: NAV
*/
nav {
  display: flex;
  gap:2em;
  width: 100%;
  justify-content: space-between;
}

nav button, nav a {
  font-size: 0.8em;
  color: var(--text-color);
  text-transform: uppercase;
  font-family: "IGBold", sans-serif;
  transition: all 0.5s;
  text-decoration: none;
}

nav a:hover {
  color: white;
  transition: all 0.2s;
}

nav .menu, nav .contact {
  display: flex;
  align-items: center;
  gap: 2em
}

nav .contact {
  align-self: flex-end;
}

nav a.mail {
  color: white;
  text-decoration: underline;
}

nav a.mail:hover {
  color: var(--accent-color);
}

nav a.highlight {
  background-color: var(--accent-color);
  padding: 1.2em 2em;
  color: var(--background-color);
  text-align: center;
}

nav a.highlight:hover {
  background-color: white;
}

/*
MARK: NAV media
*/

@media screen and (max-width: 990px) {
  nav .contact a {
    display: none;
    }

  nav .contact a.highlight {
    display: block;
  }
}

@media screen and (max-width: 520px) {
  nav {
    gap: 1em;
    justify-content: flex-start;
    flex-direction: column;
  }

  nav .contact {
    width: 100%;
  }

  nav a.highlight {
    width: 100%;
  }
}

/*
MARK: HERO
*/
section.hero {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: repeat(2, 50%);
  height: 70vh;
  min-height: 600px;
  position: relative;
}

#kreslim {
  position: absolute;
  grid-column: 2 / 2;
  grid-row: 2 / 2;
  translate: -100% -80%;
  left: 60px;
  top: 10px;
}

#tlacitka {
  position: absolute;
  grid-column: 2 / 2;
  grid-row: 2 / 2;
  translate: -50%;
  top: 30px;
}

#prinasim {
  margin-left: 0.3em;
}

#photo {
  position: absolute;
  grid-column: 2 / 2;
  grid-row: 2 / 2;
  top: 100px;
  width: 400px;
  left: 10px;
  translate: 0 -100%;
}

/*
MARK: HERO media
*/
@media screen and (max-width: 1200px) {
  #photo {
    width: 360px;
    left: 30px;
  }
}

@media screen and (max-width: 920px) {
  #photo {
    width: 320px;
    left: 30px;
  }
}

@media screen and (max-width: 770px) {
  #photo {
    width: 280px;
    top: 60px;
  }
}

@media screen and (max-width: 620px) {
  #kreslim {
    position: unset;
    grid-column: 2 / 2;
    grid-row: 2 / 2;
    translate: 0% 0%;

  }
  section.hero {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: auto;
    padding: 4em 5vw;
  }

  #tlacitka {
    position: unset;
    grid-column: 2 / 2;
    grid-row: 2 / 2;
    translate: 0%;
  }

  #prinasim {
    margin-left: 0em;
  }
  #photo {
    display: none;
  }
}

/*
MARK: APPS
*/
section.apps {
  display: grid;
  grid-template-columns: 50% 50%;
  row-gap: 1em;
}

section.apps .apps-video {
  grid-column: 1 / span 2;
  box-sizing: border-box;
  width: 100%;
}

section.apps h2 {
  font-size: 1.5rem;
  font-family: "IGBold", sans-serif;
  text-shadow: none;
  text-transform: uppercase;
}

span.app-platform {
  color: var(--accent-color);
  font-family: "IGRegular", sans-serif;
  text-transform: none;
}

.app-detail:first-of-type {
  margin-right: 1em;
  margin-left: 4em;
}
.app-detail:last-of-type {
  margin-left: 1em;
  margin-right: 4em;
}

.app-detail p {
  margin: 1em 0 2em;
}

/*
MARK: APPS media
*/

@media screen and (max-width: 950px) {
  section.apps {
      margin: 0 auto;
      padding: 2em 0;
    }
  .app-detail {
    margin: 0 !important;
    padding: 0 5vw;
  }
  .app-detail p {
    margin: 0.5em 0 1em;
  }
}

@media screen and (max-width: 620px) {
  section.apps {
    row-gap: 0em;
    }
  .app-detail {
    grid-column: 1 / 3;
    margin: 0 !important;
    padding: 0 5vw;
  }
  .app-detail p {
    margin: 0.5em 0 1em;
  }
}


/*
MARK: MOTION
*/
section.motion {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 3em;
}

#pohybuji {
  grid-column: 1 / 3;
  margin-top: 4rem;
  margin-left: 4rem;
}

#tvorim {
  grid-column: 2;
  grid-row: 2;
  margin: 1rem 1rem 1em 3em;
}

#tvorim img {
  margin-top: 1em;
  width: 1.5em;
}

section.motion .video {
  grid-row-end: span 3;
  box-sizing: border-box;
}

section.motion .video:nth-of-type(2) {
  margin-top: 8em
}

section.motion .video:nth-of-type(3) {
  margin-top: 7em;
  grid-row-end: span 2
}

section.motion .video video {
  width: 100%;
}

.description {
  margin: 1.5em 4em;
}

/*
MARK: MOTION media
*/

@media screen and (max-width: 1200px) {
  #tvorim, .description {
    margin: 1.5em 1em;
  }
}

@media screen and (max-width: 950px) {
  section.motion {
    grid-template-columns: 1fr 1fr;
  }

  #pohybuji {
    margin: 0;
  }
  #tvorim, .description {
    margin: 1.5em 0em;
  }

  #tvorim {
    grid-column: 1;
  }

  section.motion .video:nth-of-type(3) {
    display: none;
  }

  section.motion .video {
    margin-top: 1.5em;
  }
  
  section.motion .video:nth-of-type(2) {
    margin-top: 3em
  }
}

@media screen and (max-width: 620px) {
  #pohybuji {
    grid-column: 1;
  }

  section.motion {
    grid-template-columns: 1fr;
    margin-top: 3em;

  }
}

/*
MARK: FREEBIES
*/
section.freebies h2,
section.freebies h3,
.free-links {
  margin-left: 4rem;
  margin-right: 4rem;
}

section.freebies h3 {
  width: 50%;
}

.free-links {
  margin-top: 5em;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 3em;
}

.free-links img {
  width: 100%;
}
/*
MARK: FREEBIES media
*/
@media screen and (max-width: 950px) {
  section.freebies h2,
section.freebies h3,
.free-links {
  margin-left: 0;
  margin-right: 0;
}
.free-links {
  grid-template-columns: repeat(3, 1fr);
}
}

@media screen and (max-width: 620px) {
section.freebies h3 {
  width: 100%;
}

.free-links {
  grid-template-columns: 1fr 1fr;
}
}

/*
MARK: FOOTER
*/
footer h1 {
  margin: 0;
  font-weight: normal;
  font-size: 1.3rem;
}

footer .contact {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 8em;
}

.social {
  display: flex;
  flex-direction: row-reverse;
  gap: 1em;
}
footer .contact img {
  height: 1.5em;
}

footer a {
  color: var(--text-color);
  text-decoration: none;
}